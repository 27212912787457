import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function About(): JSX.Element {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <section className="about-area page-section scroll-to-page" id="about">
      <div className="custom-container">
        <div className="about-content content-width">
          <div className="section-header">
            <h4 className="subtitle scroll-animation" data-aos="fade-up">
              <i className="lar la-user"></i> About
            </h4>
          </div>
          <div className="section-header">
            <h2 className="scroll-animation" data-aos="fade-up">
              <span>Process</span> Overview
            </h2>
            <p className="scroll-animation" data-aos="fade-up">
              Working with me is simple and efficient. I start with an in-depth
              consultation to align with your vision. From there, I outline a
              strategic roadmap tailored to meet your unique goals—clear steps,
              constant updates, and rapid adjustments for the best possible
              outcome.
            </p>
          </div>
          <div className="section-header">
            <h2 className="scroll-animation" data-aos="fade-up">
              <span>Results-Driven</span> Focus
            </h2>
            <p className="scroll-animation" data-aos="fade-up">
              Your success is my success. Each solution I craft is designed to
              deliver measurable impact - cost savings, increased scalability,
              and system resilience—making your technology an asset, not a
              challenge.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
