import React from 'react';
import Lsb from './Global-content/Lsb';
import Herosec from './Main-Components/Herosec';
import About from './Main-Components/About';
import Resume from './Main-Components/Resume';
import Service from './Main-Components/Service';
import Skills from './Main-Components/Skills';
import CaseStudies from './Main-Components/CaseStudies';
// import Testimonials from './Main-Components/Testimonials';
// import Clientlogos from './Main-Components/Clientlogos';
import Packages from './Main-Components/Packages';
// import Contact from './Main-Components/Contact';

export default function Main(): JSX.Element {
  return (
    <main className="drake-main">
      <div id="smooth-wrapper">
        <div id="smooth-content">
          <Lsb /> {/* this component will used by mobile devices */}
          <Herosec />
          <About />
          <Service />
          <CaseStudies />
          <Skills />
          {/* <Testimonials /> */}
          {/* <Clientlogos /> */}
          <Packages />
          <Resume />
          {/* <Contact /> */}
        </div>
      </div>
    </main>
  );
}
