import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
//@ts-ignore
import Lightbox from 'lightbox2';
import 'lightbox2/dist/css/lightbox.min.css';
// import 'lightbox2/dist/js/lightbox.js';

export default function CaseStudies(): JSX.Element {
  useEffect(() => {
    AOS.init({ duration: 1000 });
    Lightbox.option({
      resizeDuration: 200,
      fadeDuration: 600,
      imageFadeDuration: 600,
      wrapAround: true,
    });
  }, []);
  return (
    <section
      className="portfolio-area page-section scroll-to-page"
      id="case-studies"
    >
      <div className="custom-container">
        <div className="portfolio-content content-width">
          <div className="section-header">
            <h4 className="subtitle scroll-animation" data-aos="fade-up">
              <i className="las la-grip-vertical"></i> portfolio
            </h4>
            <h1 className="scroll-animation">
              Case <span>Studies</span>
            </h1>
          </div>

          <div className="row portfolio-items">
            <div className="col-md-12 scroll-animation" data-aos="fade-up">
              <div className="portfolio-item portfolio-full">
                <h2 className="portfolio-item-title">
                  #1: Legacy System Modernization for a Financial Institution
                </h2>
                <div className="portfolio-item-inner portfolio-item-inner-text">
                  <b>Challenge:</b> Outdated architecture limited flexibility
                  and security compliance. <br />
                  <b>Solution:</b> Migrated core services to the cloud and
                  refactored legacy code for security, compliance, and
                  flexibility.
                  <br /> <b>Outcome:</b> Decreased system latency by 40%,
                  improved security, and simplified compliance updates.
                </div>
              </div>
            </div>

            <div className="col-md-12 scroll-animation" data-aos="fade-up">
              <div className="portfolio-item portfolio-full">
                <h2 className="portfolio-item-title">
                  #2: Scalable Infrastructure for E-commerce Platform
                </h2>
                <div className="portfolio-item-inner portfolio-item-inner-text">
                  <b>Challenge:</b> A growing e-commerce platform faced severe
                  performance bottlenecks during high traffic events. <br />
                  <b>Solution:</b> Implemented a microservices architecture with
                  auto-scaling on Kubernetes, optimizing workload distribution.
                  <br /> <b>Outcome:</b> Achieved 99.9% uptime during peak
                  sales, and reduced server costs by 20%.
                </div>
              </div>
            </div>

            <div className="col-md-12 scroll-animation" data-aos="fade-up">
              <div className="portfolio-item portfolio-full">
                <h2 className="portfolio-item-title">
                  #3: Cloud Migration for SaaS Provider
                </h2>
                <div className="portfolio-item-inner portfolio-item-inner-text">
                  <b>Challenge:</b> A SaaS provider needed a scalable cloud
                  solution to support fast user growth without interrupting
                  services. <br />
                  <b>Solution:</b> Designed a multi-region AWS setup with
                  automated failover and disaster recovery.
                  <br /> <b>Outcome:</b> Enhanced reliability, supporting a 10x
                  user increase with zero downtime.
                </div>
              </div>
            </div>

            <div className="col-md-12 scroll-animation" data-aos="fade-up">
              <div className="portfolio-item portfolio-full">
                <h2 className="portfolio-item-title">
                  #4: Optimizing Data Pipelines for Healthcare Analytics
                </h2>
                <div className="portfolio-item-inner portfolio-item-inner-text">
                  <b>Challenge:</b> Data latency was impacting real-time
                  analytics and decision-making. <br />
                  <b>Solution:</b> Redesigned ETL pipelines using real-time
                  processing with Apache Kafka and Spark.
                  <br /> <b>Outcome:</b> Reduced data processing time by 70%,
                  enabling actionable insights within seconds.
                </div>
              </div>
            </div>

            <div className="col-md-12 scroll-animation" data-aos="fade-up">
              <div className="portfolio-item portfolio-full">
                <h2 className="portfolio-item-title">
                  #5: Enhanced Security Architecture for Fintech Startup
                </h2>
                <div className="portfolio-item-inner portfolio-item-inner-text">
                  <b>Challenge:</b> A fintech startup needed a secure, compliant
                  architecture to handle sensitive user data. <br />
                  <b>Solution:</b> Implemented zero-trust security with
                  role-based access controls, encryption, and secure APIs.
                  <br /> <b>Outcome:</b> Achieved compliance with industry
                  standards and increased client confidence, driving a 30%
                  increase in new users.
                </div>
              </div>
            </div>

            {/* <div className="col-md-6 scroll-animation" data-aos="fade-right">
              <div className="portfolio-item portfolio-half">
                <div className="portfolio-item-inner">
                  <a
                    href="../assets/images/portfolio2.jpg"
                    data-lightbox="example-1"
                  >
                    <img
                      src="../assets/images/portfolio2.jpg"
                      alt="Portfolio"
                    />
                  </a>

                  <img src="../assets/images/portfolio2.jpg" alt="Portfolio" />

                  <ul className="portfolio-categories">
                    <li>
                      <a href="https://www.wordpress.com">WordPress</a>
                    </li>
                    <li>
                      <a href="https://www.larevel.com">Larevel/PHP</a>
                    </li>
                  </ul>
                </div>
                <h2>
                  <a href="https://www.moonex.com">Moonex WordPress Theme</a>
                </h2>
              </div>
            </div> */}

            {/* <div className="col-md-6 scroll-animation" data-aos="fade-left">
              <div className="portfolio-item portfolio-half">
                <div className="portfolio-item-inner">
                  <a
                    href="../assets/images/portfolio3.jpg"
                    data-lightbox="example-1"
                  >
                    <img
                      src="../assets/images/portfolio3.jpg"
                      alt="Portfolio"
                    />
                  </a>

                  <ul className="portfolio-categories">
                    <li>
                      <a href="https://www.figma.com">Figma</a>
                    </li>
                    <li>
                      <a href="https://www.webflow.com">Webflow</a>
                    </li>
                  </ul>
                </div>
                <h2>
                  <a href="https://www.taskly-dashboard.com">
                    Taskly Dashboard
                  </a>
                </h2>
              </div>
            </div> */}

            {/* <div className="col-md-12 scroll-animation" data-aos="fade-up">
              <div className="portfolio-item portfolio-half">
                <div className="portfolio-item-inner">
                  <a
                    href="../assets/images/portfolio4.jpg"
                    data-lightbox="example-1"
                  >
                    <img
                      src="../assets/images/portfolio4.jpg"
                      alt="portfolio"
                    />
                  </a>

                  <ul className="portfolio-categories">
                    <li>
                      <a href="https://www.figma.com">Figma</a>
                    </li>
                    <li>
                      <a href="https://www.reactjs.com">React</a>
                    </li>
                  </ul>
                </div>
                <h2>
                  <a href="https://www.hinterland.com">
                    Hinterland - Real Estate Site Redesign
                  </a>
                </h2>
              </div>
            </div> */}

            {/* <div className="col-md-12 scroll-animation" data-aos="fade-up">
              <div className="portfolio-item portfolio-half">
                <div className="portfolio-item-inner">
                  <a
                    href="../assets/images/portfolio5.jpg"
                    data-lightbox="example-1"
                  >
                    <img
                      src="../assets/images/portfolio5.jpg"
                      alt="portfolio"
                    />
                  </a>

                  <ul className="portfolio-categories">
                    <li>
                      <a href="https://www.framer.com">Framer</a>
                    </li>
                  </ul>
                </div>
                <h2>
                  <a href="https://www.lewis.com">
                    Lewis Portfolio Framer Template
                  </a>
                </h2>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}
