import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Herosec(): JSX.Element {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <section className="hero-section page-section scroll-to-page" id="home">
      <div className="custom-container">
        <div className="hero-content content-width">
          <div className="section-header">
            <h4
              className="subtitle scroll-animation"
              data-animation="fade_from_bottom"
              data-aos="fade-up"
            >
              <i className="las la-home"></i> Introduce
            </h4>
            <h1 className="scroll-animation" data-aos="fade-up">
              One <span>Architect</span>, Infinite <span>Solutions</span>!
            </h1>
          </div>
          <p className="scroll-animation" data-aos="fade-up">
            Architecture that aligns with your growth — offering full-scale
            architectural reviews, system design, and cloud migration
            strategies. Whether revamping legacy systems or setting up scalable
            infrastructures, I'm here to optimize every technical layer.
          </p>

          <div className="facts d-flex">
            <div
              className="left scroll-animation fact-item"
              data-aos="fade-right"
            >
              <h1>10</h1>
              <p>Years in Software Development</p>
            </div>
            <div
              className="right scroll-animation fact-item"
              data-aos="fade-left"
            >
              <h1>50+</h1>
              <p>cases completed</p>
            </div>
            <a
              href="#services"
              className="go-to-project-btn scroll-to scroll-animation"
              data-aos="fade-up"
            >
              <img src="../assets/images/round-text.png" alt="Rounded Text" />
              <i className="las la-arrow-down"></i>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
